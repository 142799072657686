import { BaseModal } from './BaseModal'
import {
  registerWithEmailAndPassword,
  signInWithEmail,
} from '../../services/firebase'
import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { useAlert } from '../../context/AlertContext'
import { Tab } from '@headlessui/react'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const AuthModal = ({ isOpen, handleClose }: Props) => {
  const { showSuccess: showSuccessAlert } = useAlert()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  return (
    <BaseModal title="Iniciar Sesión" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col p-2 space-y-2 w-80">
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl bg-stone-500/20 p-1 mt-1">
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-md py-2.5 text-sm font-medium leading-5 text-grey-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-green-600 focus:outline-none focus:ring-1 dark:ring-offset-stone-200 dark:focus:ring-1',
                  selected
                    ? 'bg-white shadow dark:bg-stone-200'
                    : 'text-gray-500 hover:bg-white/[0.12] hover:text-white dark:text-stone-100'
                )
              }
            >
              Ingresar
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full rounded-md py-2.5 text-sm font-medium leading-5 text-grey-700',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-green-600 focus:outline-none focus:ring-1 dark:ring-offset-stone-200 dark:focus:ring-1',
                  selected
                    ? 'bg-white shadow dark:bg-stone-200'
                    : 'text-gray-500 hover:bg-white/[0.12] hover:text-white dark:text-stone-100'
                )
              }
            >
              Registrar Cuenta
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-3">
            <Tab.Panel>
              <Card>
                <Card.Body>
                  <div className="grid grid-cols-1 gap-2">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        className="block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                        type="email"
                        placeholder="Correo electrónico"
                        value={loginEmail}
                        onChange={(e) => setLoginEmail(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        className="block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                        type="password"
                        placeholder="Contraseña"
                        value={loginPassword}
                        onChange={(e) => setLoginPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Button
                      className="w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                      variant="outline-success"
                      type="submit"
                      onClick={() => {
                        signInWithEmail(loginEmail, loginPassword).then(
                          (value) => {
                            showSuccessAlert('Inicio de Sesión Exitoso')
                            handleClose()
                          }
                        )
                      }}
                    >
                      Iniciar Sesión
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Tab.Panel>
            <Tab.Panel>
              <Card>
                <Card.Body>
                  <div className="grid grid-cols-1 gap-2">
                    <Form.Group controlId="formBasicName">
                      <Form.Control
                        className="block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                        type="text"
                        placeholder="Usuario"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        className="block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                        type="email"
                        placeholder="Correo Electrónico"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        className="block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                        type="password"
                        placeholder="Contraseña"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Button
                      className="w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                      variant="outline-danger"
                      type="submit"
                      onClick={() => {
                        registerWithEmailAndPassword(
                          name,
                          email,
                          password
                        ).then(() => {
                          showSuccessAlert('Registro Exitoso')
                          handleClose()
                        })
                      }}
                    >
                      Confirmar
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </BaseModal>
  )
}
