export function getWeekNumberUTC(): number {
  // Copy date so don't modify original
  const date = new Date()
  const d = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  )
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(
    ((d.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7
  )
  // Return array of year and week number
  return weekNo
}

export function getWeekNumber(): number {
  // Copy date so don't modify original
  const date = new Date()
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  date.setDate(date.getDate() + 4 - (date.getDay() || 7))
  // Get first day of year
  var yearStart = new Date(date.getFullYear(), 0, 1)
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(
    ((date.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7
  )
  // Return array of year and week number
  return weekNo
}
