import Countdown from 'react-countdown'
import { GameStats } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
import { tomorrow } from '../../lib/words'
import { BaseModal } from './BaseModal'
import {
  STATISTICS_TITLE,
  GUESS_DISTRIBUTION_TEXT,
  NEW_WORD_TEXT,
  SHARE_TEXT,
} from '../../constants/strings'
import { useEffect, useState } from 'react'
import { ProgressLeaderboard } from '../stats/ProgressLeaderboard'
import { StatBar } from '../stats/StatBar'
import { getStatsFirebaseWeekly } from '../../services/firebase'

type Props = {
  isOpen: boolean
  handleClose: () => void
  solution: string
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
}

export const LeaderboardModal = ({
  isOpen,
  handleClose,
  solution,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
  numberOfGuessesMade,
}: Props) => {
  let [userScores, setUserScores] = useState([])
  let maxValue = 0

  useEffect(() => {
    if (isOpen) {
      getUserScores()
    }
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  const getUserScores = async () => {
    await getStatsFirebaseWeekly().then((value: any) => {
      userScores = value
      //maxValue = Math.max(...value, 1)
      if (userScores) setUserScores(value)
    })
  }

  return (
    <BaseModal title="Puntajes" isOpen={isOpen} handleClose={handleClose}>
      <StatBar gameStats={gameStats} />
      <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
        Tabla de Resultados
      </h4>
      <div className="columns-1 justify-left m-2 mt-1 text-sm dark:text-white">
        {userScores.map((userScore: any, i) => (
          <ProgressLeaderboard
            key={i}
            name={userScore.name}
            index={i}
            isCurrentDayStatRow={i === 1 ? false : true}
            //size={90 * (value / maxValue)}
            size={90}
            label={userScore.name + ' - ' + userScore.score + ' Puntos'}
          />
        ))}
      </div>
    </BaseModal>
  )
}
