import firebase from 'firebase/compat/app'
import {
  doc,
  getFirestore,
  collection,
  addDoc,
  where,
  query,
  getDocs,
  serverTimestamp,
  setDoc,
  orderBy,
  limit,
  getDoc,
} from 'firebase/firestore'

// Firebase Integration
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth'
import { getWeekNumber } from '../lib/date'
import { UserAddIcon } from '@heroicons/react/outline'

// Initialize Firebase
const firebaseApp = initializeApp({
  apiKey: 'AIzaSyB078lW71U9i8XFFOU0cU41tD7pdjiLB5M',
  authDomain: 'wordleague-5540c.firebaseapp.com',
  projectId: 'wordleague-5540c',
  storageBucket: 'wordleague-5540c.appspot.com',
  messagingSenderId: '160233601405',
  appId: '1:160233601405:web:81d6c3f4efd8d9c2117414',
  measurementId: 'G-NEXDNN1K9M',
})
const db = getFirestore()

export const auth = getAuth(firebaseApp)
export default firebase

export const signInWithEmail = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    return true
  } catch (err: any) {
    alert(err.message)
    throw err
  }
}

auth.onAuthStateChanged((user) => {
  if (user) {
    const { currentUser } = auth
    console.log('Currently logged in user', currentUser)
    // store.dispatch(userLoggedIn(currentUser.uid))
    // browserHistory.push('/newsfeed')
    // save the current user's uid to redux store.
  } else {
    // browserHistory.push('/signin')
    // delete the current user's uid from the redux store.
  }
})

export const isUserLoggedIn = () => {
  if (auth) return true
  else return false
}

export const logOutUser = (callback: Function) => {
  auth.signOut().then((value) => {
    callback()
  })
}

export const registerWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user
    await updateProfile(user, {
      displayName: name,
    })
    if (user) {
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name,
      })
    } else {
      throw new Error('User not found')
    }
  } catch (err: any) {
    alert(err.message)
  }
}

// const sendPasswordResetEmail = async (email) => {
//   try {
//     await auth.sendPasswordResetEmail(email);
//     alert("Password reset link sent!");
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };
// const logout = () => {
//   auth.signOut();
// };

export const saveStatsToFirebase = async (score: number, solution: string) => {
  const weekNumber: number = getWeekNumber()
  let userWeeklyScore = 0
  let username = ''
  let userid = ''
  const userScores = []
  try {
    if (auth && auth.currentUser && auth.currentUser.uid) {
      const queryUsers = query(
        collection(db, 'users'),
        where('uid', '==', auth.currentUser.uid)
      )
      const snapshotUsers = await getDocs(queryUsers)

      for (const user of snapshotUsers.docs) {
        username = user.data().name
        userid = user.id
      }

      await addDoc(collection(db, 'users', userid, 'games'), {
        word: solution,
        date: new Date(),
        tries: '', //TODO: Tries
        score: score,
        week: weekNumber,
      })

      //   const docRefWeeklyScore = doc(
      //     db,
      //     'weeklyScores',
      //     weekNumber.toString() + '.' + userid
      //   )
      //   const docSnapWeeklyScore = await getDoc(docRefWeeklyScore)

      //   if (docSnapWeeklyScore.exists()) {
      //     userWeeklyScore = score + docSnapWeeklyScore.data().score
      //   } else {
      //     userWeeklyScore = score
      //   }

      // Calculate current score
      const gamesQuery = query(
        collection(db, 'users', userid, 'games'),
        where('week', '==', weekNumber)
      )
      const gamesQuerySnapshot = await getDocs(gamesQuery)

      for (const gamesDoc of gamesQuerySnapshot.docs) {
        userScores.push(gamesDoc.data().score)
      }
      userWeeklyScore = userScores
        .sort((a, b) => b - a)
        .slice(0, 5)
        .reduce((partialSum, a) => partialSum + a, 0)

      await setDoc(
        doc(db, 'weeklyScores', weekNumber.toString() + '.' + userid),
        {
          week: weekNumber,
          score: userWeeklyScore,
          name: username,
          userId: userid,
        }
      )
    }
  } catch (err: any) {
    console.error(err.message)
  }
}

export const getStatsFirebaseWeekly = async () => {
  try {
    const weekNumber: number = getWeekNumber()
    let leagueScores = []
    let currentHighscore = 0
    let lastWeekHighscore = 0
    let lastWeekWinnerId = ''

    // Query this weeks Top 10
    const queryWeeklyScores = query(
      collection(db, 'weeklyScores'),
      orderBy('score', 'desc'),
      where('score', '>', 0),
      where('week', '==', weekNumber),
      limit(10)
    )
    const snapshotWeeklyScores = await getDocs(queryWeeklyScores)

    if (snapshotWeeklyScores.docs.length) {
      currentHighscore = snapshotWeeklyScores.docs[0].data().score
    }

    for (const weeklyScore of snapshotWeeklyScores.docs) {
      const user = new UserScore()
      user.name = weeklyScore.data().name
      user.id = weeklyScore.data().userId

      if (weeklyScore.data().week === weekNumber) {
        user.score = weeklyScore.data().score
        if (weeklyScore.data().score === currentHighscore) user.isWinning = true
        leagueScores.push(user)
      }
    }

    // Check if current player not in Top 10 and add to last spot
    if (auth && auth.currentUser && auth.currentUser.uid) {
      let userId = ''
      const queryUsers = query(
        collection(db, 'users'),
        where('uid', '==', auth.currentUser.uid)
      )
      const snapshotUsers = await getDocs(queryUsers)

      for (const user of snapshotUsers.docs) {
        userId = user.id
      }
      // Check if player ID is in current list
      if (leagueScores.findIndex((obj) => obj.id === userId) === -1) {
        //Fetch current's user score

        const userWeeklyScoreRef = doc(
          db,
          'weeklyScores',
          weekNumber + '.' + userId
        )
        const userWeeklyScoreSnap = await getDoc(userWeeklyScoreRef)

        if (userWeeklyScoreSnap.exists()) {
          const userScore = new UserScore()
          userScore.score = userWeeklyScoreSnap.data().score
          userScore.name = userWeeklyScoreSnap.data().name
          userScore.id = userWeeklyScoreSnap.data().userId
          userScore.isLast = true
          console.log(userScore)
          leagueScores.push(userScore)
        }
      }

      // TODO: Work on draws by querying last week scores from everyone in the top10
      // Check for last week winner and mark him
      const queryLastWeekScores = query(
        collection(db, 'weeklyScores'),
        orderBy('score', 'desc'),
        where('score', '>', 0),
        where('week', '==', weekNumber - 1),
        limit(1)
      )
      const snapshotLastWeekScores = await getDocs(queryLastWeekScores)

      if (snapshotLastWeekScores.docs.length) {
        lastWeekWinnerId = snapshotLastWeekScores.docs[0].data().userId
      }

      for (const userScores of leagueScores) {
        if (lastWeekWinnerId === userScores.id)
          userScores.isLastWeekWinner = true
      }
    }

    return leagueScores
  } catch (err: any) {
    console.error(err.message)
  }
}

class UserScore {
  id: string = ''
  score: number = 0
  lastWeekScore: number = 0
  name: string = ''
  isWinning: boolean = false
  isLastWeekWinner: boolean = false
  isLast: boolean = false
}

// export const updateScores = async () => {
//   const weekNumber: number = getWeekNumber()
//   try {
//     if (auth && auth.currentUser && auth.currentUser.uid) {
//       const queryUsers = query(collection(db, 'users'))
//       const snapshotUsers = await getDocs(queryUsers)

//       for (const user of snapshotUsers.docs) {
//         let userWeeklyScore = 0
//         let username = ''
//         let userid = ''
//         const userScores = []
//         username = user.data().name
//         userid = user.id

//         // Calculate current score
//         const gamesQuery = query(
//           collection(db, 'users', userid, 'games'),
//           where('week', '==', weekNumber)
//         )
//         const gamesQuerySnapshot = await getDocs(gamesQuery)

//         for (const gamesDoc of gamesQuerySnapshot.docs) {
//           userScores.push(gamesDoc.data().score)
//         }
//         userWeeklyScore = userScores
//           .sort((a, b) => b - a)
//           .slice(0, 5)
//           .reduce((partialSum, a) => partialSum + a, 0)

//         await setDoc(
//           doc(db, 'weeklyScores', weekNumber.toString() + '.' + userid),
//           {
//             week: weekNumber,
//             score: userWeeklyScore,
//             name: username,
//             userId: userid,
//           }
//         )
//       }
//     }
//   } catch (err: any) {
//     console.error(err.message)
//   }
// }

// export const getStatsFirebase = async () => {
//   try {
//     const today = new Date()

//     // Calculate current week range
//     const startOfWeek = new Date(
//       today.setDate(
//         today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
//       )
//     )
//     startOfWeek.setHours(0, 0, 0)

//     const endOfWeek = new Date(
//       today.setDate(today.getDate() - today.getDay() + 7)
//     )
//     endOfWeek.setHours(23, 59, 0)

//     //alert('Start: ' + startOfWeek + ' End ' + endOfWeek)

//     // Calculate last week range
//     const startLastWeek = new Date(
//       today.setDate(startOfWeek.getDate() - today.getDay() - 7)
//     )
//     startLastWeek.setHours(0, 0, 0)
//     const endLastWeek = new Date(today.setDate(startLastWeek.getDate() + 6))
//     endLastWeek.setHours(23, 59, 0)
//     //   alert(
//     //     'Start Last Week: ' + startLastWeek + ' End Last Week ' + endLastWeek
//     //   )

//     const queryUsers = query(
//       collection(db, 'users')
//       //where('uid', '==', auth?.currentUser?.uid)
//     )
//     const querySnapshot = await getDocs(queryUsers)
//     let leagueScores = []
//     for (const userDoc of querySnapshot.docs) {
//       const userScores = []
//       const lastWeekUserScores = []
//       const user = {
//         score: 0,
//         lastWeekScore: 0,
//         name: userDoc.data().name,
//         isWinner: false,
//       }

//       // Calculate current score
//       const gamesQuery = query(
//         collection(db, 'users', userDoc.id, 'games'),
//         where('date', '>', startOfWeek),
//         where('date', '<', endOfWeek)
//       )
//       const gamesQuerySnapshot = await getDocs(gamesQuery)

//       for (const gamesDoc of gamesQuerySnapshot.docs) {
//         //console.log(gamesDoc.id, ' => ', gamesDoc.data())
//         userScores.push(gamesDoc.data().score)
//       }
//       user.score = userScores
//         .sort((a, b) => b - a)
//         .slice(0, 5)
//         .reduce((partialSum, a) => partialSum + a, 0)

//       // Calculate last week score
//       const lastWeekQuery = query(
//         collection(db, 'users', userDoc.id, 'games'),
//         where('date', '>', startLastWeek),
//         where('date', '<', endLastWeek)
//       )
//       const lastWeekSnapshot = await getDocs(lastWeekQuery)

//       for (const lastWeekDoc of lastWeekSnapshot.docs) {
//         //console.log(gamesDoc.id, ' => ', gamesDoc.data())
//         lastWeekUserScores.push(lastWeekDoc.data().score)
//       }
//       user.lastWeekScore = lastWeekUserScores
//         .sort((a, b) => b - a)
//         .slice(0, 5)
//         .reduce((partialSum, a) => partialSum + a, 0)

//       leagueScores.push(user)
//     }
//     //Sort to add trophy for winner of last week
//     leagueScores = leagueScores
//       .slice()
//       .sort((a, b) => b.lastWeekScore - a.lastWeekScore)
//     //leagueScores[0].name = '🏆 ' + leagueScores[0].name
//     leagueScores[0].isWinner = true
//     // Filter all scores below 0
//     leagueScores = leagueScores.filter((leagueScore) => leagueScore.score > 0)
//     //Sort for winner of this week
//     leagueScores = leagueScores.slice().sort((a, b) => b.score - a.score)
//     return leagueScores
//   } catch (err: any) {
//     alert(err.message)
//   }
// }
