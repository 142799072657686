import { Fragment } from 'react'
import { Transition, Menu } from '@headlessui/react'
import {
  ChartBarIcon,
  CogIcon,
  InformationCircleIcon,
  XCircleIcon,
  LogoutIcon,
  UserIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'
import { auth, logOutUser } from '../../services/firebase'
import { useAlert } from '../../context/AlertContext'

type Props = {
  isOpen: boolean
  handleClose: () => void
  setIsAuthModalOpen: (value: boolean) => void
  setIsLeaderboardModalOpen: (value: boolean) => void
  setIsInfoModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
}

export const SideMenu = ({
  isOpen,
  handleClose,
  setIsAuthModalOpen,
  setIsInfoModalOpen,
  setIsStatsModalOpen,
  setIsSettingsModalOpen,
}: Props) => {
  const { showSuccess: showSuccessAlert } = useAlert()

  let profileItem = (
    <Menu.Item
      as="div"
      className="my-3 text-xl font-medium text-stone-900 dark:text-stone-100"
      onClick={() => {
        handleClose()
        setIsAuthModalOpen(true)
      }}
    >
      {({ active }) => (
        <div>
          <UserIcon className="inline-block align-middle h-6 w-6 mr-2 cursor-pointer dark:stroke-white" />
          <span className="inline-block align-middle">Iniciar Sesión</span>
        </div>
      )}
    </Menu.Item>
  )

  const displayName = auth?.currentUser?.displayName
  if (displayName) {
    profileItem = (
      <Menu.Item
        as="div"
        className="my-3 text-xl font-medium text-stone-900 dark:text-stone-100"
      >
        {({ disabled }) => (
          <div>
            <UserIcon className="inline-block align-middle h-6 w-6 mr-2 cursor-pointer dark:stroke-white" />
            <span className="inline-block align-middle">{displayName}</span>
          </div>
        )}
      </Menu.Item>
    )
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <div className="fixed z-10 inset-0 h-screen overflow-y-auto">
        <div className="flex items-center justify-center h-[100%] text-center ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-stone-500 bg-opacity-75 transition-opacity"
              onClick={() => handleClose()}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 -translate-x-10"
            enterTo="opacity-100 translate-x-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 -translate-x-10"
          >
            <div className="absolute inset-y-0 left-0 h-full w-4/6 bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all dark:bg-stone-800">
              <Menu>
                <button
                  onClick={() => handleClose()}
                  tabIndex={0}
                  aria-pressed="false"
                  className="absolute right-4 top-4"
                >
                  <XCircleIcon className="h-6 w-6 cursor-pointer dark:stroke-white" />
                </button>
                <Menu.Items
                  static
                  className="mt-8 origin-top-right divide-y divide-gray-100"
                >
                  <div>
                    {profileItem}
                    {/* <Menu.Item
                      as="div"
                      className="my-3 text-xl font-medium text-stone-900 dark:text-stone-100"
                    >
                      {({ active }) => (
                        <div>
                          <UserGroupIcon className="inline-block align-middle h-6 w-6 mr-2 cursor-pointer dark:stroke-white" />
                          <span className="inline-block align-middle">
                            Ligas
                          </span>
                        </div>
                      )}
                    </Menu.Item> */}
                    <Menu.Item
                      as="div"
                      className="my-3 text-xl font-medium text-stone-900 dark:text-stone-100"
                      onClick={() => {
                        handleClose()
                        setIsStatsModalOpen(true)
                      }}
                    >
                      {({ active }) => (
                        <div>
                          <ChartBarIcon className="inline-block align-middle h-6 w-6 mr-2 cursor-pointer dark:stroke-white" />
                          <span className="inline-block align-middle">
                            Puntajes
                          </span>
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item
                      as="div"
                      className="my-3 text-xl font-medium text-stone-900 dark:text-stone-100"
                      onClick={() => {
                        handleClose()
                        setIsSettingsModalOpen(true)
                      }}
                    >
                      {({ active }) => (
                        <div>
                          <CogIcon className="inline-block align-middle h-6 w-6 mr-2 cursor-pointer dark:stroke-white" />
                          <span className="inline-block align-middle">
                            Ajustes
                          </span>
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item
                      as="div"
                      className="my-3 text-xl font-medium text-stone-900 dark:text-stone-100"
                      onClick={() => {
                        handleClose()
                        setIsInfoModalOpen(true)
                      }}
                    >
                      {({ active }) => (
                        <div>
                          <InformationCircleIcon className="inline-block align-middle h-6 w-6 mr-2 cursor-pointer dark:stroke-white" />
                          <span className="inline-block align-middle">
                            Acerca
                          </span>
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item
                      as="div"
                      className="my-3 text-xl font-medium text-stone-900 dark:text-stone-100"
                      onClick={() => {
                        handleClose()
                        logOutUser(() => {
                          showSuccessAlert('Sesión Cerrada')
                        })
                      }}
                    >
                      {({ active }) => (
                        <div>
                          <LogoutIcon className="inline-block align-middle h-6 w-6 mr-2 cursor-pointer dark:stroke-white" />
                          <span className="inline-block align-middle">
                            Cerrar Sesión
                          </span>
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Menu>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  )
}
