export const WORDS = [
    'pulpo',
    'arpon',
    'menta',
    'quema',
    'arabe',
    'pedir',
    'samba',
    'deuda',
    'junio',
    'pagar',
    'donar',
    'brazo',
    'baila',
    'menos',
    'monje',
    'salvo',
    'luego',
    'jamas',
    'señal',
    'cidra',
    'rallo',
    'monte',
    'macho',
    'norma',
    'gozar',
    'joven',
    'pitar',
    'lento',
    'facil',
    'limon',
    'fresa',
    'yerno',
    'sismo',
    'carne',
    'medir',
    'minar',
    'tenso',
    'ardor',
    'hacer',
    'funda',
    'volar',
    'amado',
    'velar',
    'llena',
    'sordo',
    'bravo',
    'matar',
    'manta',
    'etapa',
    'duque',
    'ozono',
    'banco',
    'mixto',
    'venia',
    'gesto',
    'secar',
    'habla',
    'ganar',
    'dicha',
    'vocal',
    'plano',
    'freir',
    'acabe',
    'rogar',
    'besar',
    'vuelo',
    'acero',
    'cacao',
    'nieve',
    'vasto',
    'agudo',
    'buque',
    'ideal',
    'oeste',
    'frito',
    'costa',
    'tripa',
    'pañal',
    'decir',
    'olivo',
    'rollo',
    'fumar',
    'ducha',
    'audaz',
    'haber',
    'palma',
    'flojo',
    'sexta',
    'madre',
    'etico',
    'rival',
    'corte',
    'sueño',
    'vacio',
    'oasis',
    'entre',
    'santo',
    'sorda',
    'reina',
    'suiza',
    'falta',
    'oruga',
    'milla',
    'cerdo',
    'verde',
    'heroe',
    'caido',
    'parte',
    'fruta',
    'duelo',
    'braza',
    'menor',
    'tarta',
    'clima',
    'torre',
    'ultra',
    'celda',
    'vejez',
    'lavar',
    'dolar',
    'cerro',
    'culto',
    'unico',
    'falso',
    'grifo',
    'melon',
    'asado',
    'perro',
    'rayar',
    'ritmo',
    'varon',
    'mejor',
    'calor',
    'perla',
    'frase',
    'jurar',
    'apego',
    'ajeno',
    'banca',
    'enojo',
    'tinta',
    'silla',
    'armar',
    'regla',
    'litro',
    'corto',
    'jamon',
    'ancho',
    'tucan',
    'lirio',
    'bañar',
    'largo',
    'golpe',
    'ahora',
    'fruto',
    'grasa',
    'firme',
    'prado',
    'buena',
    'tacon',
    'cesta',
    'saber',
    'multa',
    'chivo',
    'clave',
    'cupon',
    'santa',
    'doler',
    'aleta',
    'pisar',
    'cuero',
    'nobel',
    'breve',
    'punto',
    'gallo',
    'talon',
    'calzo',
    'cruel',
    'mundo',
    'metal',
    'epico',
    'vista',
    'fibra',
    'suela',
    'metro',
    'tieso',
    'mujer',
    'jeque',
    'guapo',
    'circo',
    'pasta',
    'abrir',
    'añejo',
    'velon',
    'pecho',
    'mamut',
    'vodka',
    'huida',
    'jarro',
    'gorra',
    'silba',
    'avena',
    'techo',
    'pluma',
    'costo',
    'quiza',
    'crema',
    'conga',
    'polar',
    'mucho',
    'hongo',
    'vagon',
    'peine',
    'pecar',
    'aldea',
    'arbol',
    'panza',
    'maton',
    'visto',
    'justo',
    'latir',
    'tapar',
    'honda',
    'valle',
    'curvo',
    'bruto',
    'basta',
    'feria',
    'hacia',
    'polea',
    'torta',
    'retar',
    'conde',
    'hecha',
    'nueve',
    'jugar',
    'fondo',
    'labor',
    'docil',
    'dicho',
    'cojin',
    'fusil',
    'amigo',
    'pizza',
    'toldo',
    'oveja',
    'mitad',
    'pulso',
    'chapa',
    'fugaz',
    'crear',
    'lapso',
    'tacto',
    'venus',
    'suizo',
    'mutua',
    'desde',
    'humor',
    'calva',
    'mural',
    'barco',
    'sucio',
    'dudar',
    'igual',
    'grado',
    'retro',
    'motor',
    'otoño',
    'lucha',
    'entro',
    'virus',
    'grupo',
    'mango',
    'logos',
    'buque',
    'pulir',
    'roble',
    'paseo',
    'acabo',
    'salve',
    'opaco',
    'cacho',
    'gramo',
    'plaga',
    'aviso',
    'cebra',
    'miedo',
    'brujo',
    'viral',
    'morse',
    'mirar',
    'coran',
    'pilla',
    'lunes',
    'adoro',
    'carro',
    'adios',
    'rueda',
    'tilde',
    'dedal',
    'atico',
    'mamar',
    'bruma',
    'pajar',
    'arduo',
    'serie',
    'gripe',
    'relax',
    'resto',
    'vivir',
    'barra',
    'idolo',
    'blusa',
    'huevo',
    'falla',
    'tosca',
    'barro',
    'libra',
    'tigre',
    'liana',
    'fluor',
    'solar',
    'balde',
    'tarro',
    'usted',
    'señor',
    'siglo',
    'mamba',
    'poner',
    'mutar',
    'bingo',
    'chino',
    'veloz',
    'piojo',
    'raspa',
    'cauce',
    'azote',
    'satan',
    'museo',
    'fijar',
    'veraz',
    'hidra',
    'apuro',
    'obeso',
    'anexo',
    'sesgo',
    'pinta',
    'silbo',
    'apoyo',
    'creer',
    'turno',
    'aguar',
    'exodo',
    'sabio',
    'chulo',
    'grata',
    'polen',
    'legal',
    'rifle',
    'huido',
    'bolso',
    'libro',
    'pared',
    'ligar',
    'jarra',
    'tumbo',
    'marca',
    'soplo',
    'yerba',
    'mareo',
    'tibia',
    'gasto',
    'poker',
    'bario',
    'felpa',
    'natal',
    'visor',
    'negro',
    'colon',
    'peste',
    'marzo',
    'grada',
    'deber',
    'rodar',
    'ruego',
    'doblo',
    'varar',
    'termo',
    'amiga',
    'viola',
    'preso',
    'mecer',
    'pardo',
    'lista',
    'zafar',
    'cavar',
    'furia',
    'pobre',
    'licra',
    'villa',
    'casta',
    'karma',
    'estos',
    'algun',
    'dosis',
    'album',
    'tunel',
    'renal',
    'negra',
    'hotel',
    'canoa',
    'datil',
    'secta',
    'copia',
    'brota',
    'ameno',
    'tarea',
    'junto',
    'total',
    'zorra',
    'horca',
    'fallo',
    'canje',
    'perra',
    'novel',
    'beber',
    'sitio',
    'cobra',
    'trigo',
    'comun',
    'lance',
    'tenue',
    'baile',
    'aereo',
    'fluir',
    'naval',
    'falte',
    'casco',
    'patan',
    'bordo',
    'lloro',
    'renta',
    'obvio',
    'salud',
    'soñar',
    'lindo',
    'nasal',
    'tesis',
    'tosco',
    'texto',
    'canso',
    'poema',
    'rotar',
    'galon',
    'patio',
    'acato',
    'horno',
    'mixta',
    'ambar',
    'dorar',
    'broma',
    'cruza',
    'extra',
    'canto',
    'acido',
    'rasgo',
    'liceo',
    'gente',
    'viudo',
    'mafia',
    'antro',
    'borda',
    'recto',
    'tabla',
    'rezar',
    'zurda',
    'truco',
    'trama',
    'malla',
    'tallo',
    'limbo',
    'guiño',
    'poeta',
    'hecho',
    'busto',
    'narco',
    'rombo',
    'trata',
    'lleva',
    'trazo',
    'cruce',
    'iluso',
    'pegar',
    'piano',
    'cifra',
    'erizo',
    'disco',
    'abuso',
    'ciego',
    'hedor',
    'dejar',
    'lente',
    'helar',
    'labio',
    'sacar',
    'agrio',
    'rosal',
    'arnes',
    'bajio',
    'mente',
    'nadar',
    'robot',
    'civil',
    'audio',
    'meter',
    'habil',
    'zanja',
    'grapa',
    'labia',
    'venda',
    'lleve',
    'caida',
    'goteo',
    'error',
    'monta',
    'llano',
    'claro',
    'sodio',
    'buceo',
    'plaza',
    'sobra',
    'rugir',
    'socio',
    'rubio',
    'timon',
    'autor',
    'ansia',
    'favor',
    'parir',
    'tribu',
    'cajon',
    'garra',
    'rocio',
    'silva',
    'mudar',
    'tenaz',
    'anual',
    'ataud',
    'lapiz',
    'panal',
    'sabor',
    'subir',
    'bonus',
    'china',
    'lijar',
    'pecio',
    'bazar',
    'curva',
    'tango',
    'lunar',
    'acaso',
    'lugar',
    'toser',
    'lleno',
    'tumor',
    'clavo',
    'optar',
    'hasta',
    'flema',
    'bicho',
    'braga',
    'cloro',
    'burla',
    'botin',
    'modal',
    'llega',
    'hacha',
    'rumor',
    'sazon',
    'jalar',
    'mutuo',
    'femur',
    'rodeo',
    'quemo',
    'asilo',
    'gorda',
    'queja',
    'justa',
    'tener',
    'falto',
    'crudo',
    'goles',
    'cobre',
    'tecla',
    'vello',
    'campo',
    'posar',
    'rango',
    'caoba',
    'pedal',
    'nuevo',
    'monto',
    'telon',
    'culpa',
    'ebrio',
    'hueso',
    'nieto',
    'simio',
    'pinza',
    'cielo',
    'carne',
    'atajo',
    'lejos',
    'herir',
    'rinde',
    'bambu',
    'acose',
    'julio',
    'marco',
    'balon',
    'duela',
    'serio',
    'donde',
    'bolsa',
    'turbo',
    'osado',
    'borde',
    'botar',
    'racha',
    'cisne',
    'nalga',
    'turco',
    'corta',
    'fuera',
    'parar',
    'criar',
    'lucro',
    'judio',
    'ancla',
    'bruja',
    'icono',
    'ancha',
    'doble',
    'arena',
    'toque',
    'juego',
    'gusto',
    'zurdo',
    'cante',
    'dueña',
    'titan',
    'talco',
    'obrar',
    'migra',
    'tomar',
    'valla',
    'harpa',
    'curar',
    'balsa',
    'farol',
    'union',
    'pausa',
    'talla',
    'abril',
    'idear',
    'araña',
    'verbo',
    'banal',
    'exito',
    'bufon',
    'salsa',
    'salva',
    'rural',
    'ladra',
    'logro',
    'potra',
    'tinte',
    'feroz',
    'mimar',
    'cabra',
    'ronca',
    'nadie',
    'barca',
    'comer',
    'rimar',
    'abajo',
    'abono',
    'buzon',
    'cenar',
    'nevar',
    'larva',
    'gorro',
    'poste',
    'debil',
    'banda',
    'hiena',
    'marea',
    'traje',
    'regar',
    'plena',
    'niego',
    'tonto',
    'himno',
    'bucal',
    'valia',
    'torpe',
    'listo',
    'puñal',
    'virgo',
    'letal',
    'hondo',
    'podar',
    'pongo',
    'pollo',
    'mojar',
    'deseo',
    'ruido',
    'rodea',
    'coral',
    'nunca',
    'bajon',
    'clase',
    'firma',
    'dolor',
    'chica',
    'plata',
    'cañon',
    'domar',
    'mambo',
    'diana',
    'pasar',
    'saldo',
    'ceder',
    'morir',
    'clava',
    'lacio',
    'trozo',
    'remar',
    'ganso',
    'coche',
    'tanga',
    'donde',
    'equis',
    'rampa',
    'movil',
    'ciclo',
    'garza',
    'savia',
    'narra',
    'ovalo',
    'rumbo',
    'color',
    'trono',
    'oscar',
    'venta',
    'acera',
    'signo',
    'pieza',
    'panel',
    'burro',
    'danza',
    'queso',
    'marte',
    'falsa',
    'rabia',
    'estar',
    'girar',
    'pelea',
    'pelar',
    'radar',
    'polvo',
    'globo',
    'nivel',
    'calmo',
    'bajar',
    'primo',
    'votar',
    'dieta',
    'sobar',
    'altar',
    'letra',
    'tanto',
    'humus',
    'droga',
    'vivaz',
    'valor',
    'salon',
    'noche',
    'orden',
    'verso',
    'tramo',
    'chile',
    'vieja',
    'boton',
    'robar',
    'ellos',
    'drama',
    'atras',
    'mayor',
    'mania',
    'causa',
    'regir',
    'sedal',
    'temer',
    'susto',
    'guiar',
    'capaz',
    'atado',
    'hueco',
    'patas',
    'freno',
    'teñir',
    'batir',
    'mocho',
    'traer',
    'clara',
    'angel',
    'novio',
    'salir',
    'envio',
    'digno',
    'tropa',
    'avion',
    'boxeo',
    'tibio',
    'radio',
    'viuda',
    'cuota',
    'tumba',
    'prima',
    'dardo',
    'opera',
    'patin',
    'presa',
    'temor',
    'koala',
    'sudar',
    'plato',
    'echar',
    'judia',
    'brisa',
    'llave',
    'ramos',
    'flaco',
    'cerca',
    'motel',
    'grito',
    'golfo',
    'suelo',
    'grato',
    'laser',
    'bando',
    'trapo',
    'lemur',
    'viejo',
    'bueno',
    'rehen',
    'raton',
    'farsa',
    'reloj',
    'bomba',
    'sexto',
    'fideo',
    'rapar',
    'pista',
    'plebe',
    'siete',
    'axila',
    'cinta',
    'pesar',
    'quita',
    'tinto',
    'zombi',
    'indio',
    'sutil',
    'aroma',
    'tirar',
    'usual',
    'arroz',
    'sable',
    'ayuda',
    'recta',
    'cinco',
    'calvo',
    'grave',
    'tenor',
    'grita',
    'cargo',
    'sello',
    'cazar',
    'linea',
    'oliva',
    'media',
    'calma',
    'graso',
    'oreja',
    'flota',
    'pezon',
    'aguja',
    'sudor',
    'mando',
    'macro',
    'apodo',
    'llama',
    'ojera',
    'plomo',
    'cegar',
    'selva',
    'trece',
    'galan',
    'canal',
    'arder',
    'brava',
    'barba',
    'tutor',
    'tarde',
    'lider',
    'poder',
    'tauro',
    'libre',
    'salio',
    'ruedo',
    'fauna',
    'fecha',
    'matiz',
    'errar',
    'calle',
    'gordo',
    'espia',
    'local',
    'abeja',
    'rozar',
    'enero',
    'pudor',
    'bruta',
    'zorro',
    'suero',
    'carpa',
    'vagar',
    'negar',
    'basto',
    'papel',
    'viaje',
    'sauna',
    'pleno',
    'junta',
    'padre',
    'cueva',
    'sanar',
    'suave',
    'placa',
    'trago',
    'epica',
    'noble',
    'razon',
    'vicio',
    'vigor',
    'arpia',
    'fosil',
    'pacto',
    'usado',
    'segun',
    'ruina',
    'dañar',
    'vapor',
    'ficha',
    'panda',
    'honor',
    'pudin',
    'atomo',
    'sonar',
    'meson',
    'coger',
    'ambos',
    'leche',
    'hielo',
    'tocar',
    'riego',
    'molde',
    'plato',
    'pasto',
    'queda',
    'momia',
    'larga',
    'tenis',
    'moral',
    'peina',
    'dulce',
    'valer',
    'feliz',
    'rumba',
    'grano',
    'dueño',
    'muela',
    'punta',
    'venir',
    'genio',
    'india',
    'epoca',
    'carta',
    'bello',
    'lanza',
    'actor',
    'nariz',
    'bahia',
    'orine',
    'ostra',
    'durar',
    'picar',
    'medio',
    'muslo',
    'sobre',
    'trato',
    'pulga',
    'andar',
    'odiar',
    'nueva',
    'forma',
    'jabon',
    'nacer',
    'salto',
    'flora',
    'vital',
    'busco',
    'cable',
    'enano',
    'vulva',
    'hurto',
    'parto',
    'canta',
    'carga',
    'torso',
    'falda',
    'fiera',
    'senil',
    'antes',
    'rosca',
    'citar',
    'golfa',
    'jaula',
    'cocer',
    'lamer',
    'flujo',
    'baron',
    'fuego',
    'elite',
    'riñon',
    'etica',
    'quien',
    'coser',
    'hogar',
    'final',
    'quien',
    'curso',
    'busca',
    'fatal',
    'mosca',
    'niñez'
]