import classNames from 'classnames'

type Props = {
  index: number
  name: string
  size: number
  label: string
  isCurrentDayStatRow: boolean
}

export const ProgressLeaderboard = ({
  index,
  size,
  label,
  isCurrentDayStatRow,
}: Props) => {
  const currentRowClass = classNames(
    'text-xs font-medium text-green-100 text-center p-0.5',
    {
      'bg-green-600': isCurrentDayStatRow,
      'bg-stone-600': !isCurrentDayStatRow,
    }
  )
  return (
    <div>
      {index === 10 && (
        <div className="flex justify-left m-1 ">
          <div className="items-center justify-center w-2">
            {index < 10 ? index + 1 : ''}
          </div>
          <div className="w-full ml-2">
            <div style={{ width: `${8 + size}%` }}>···</div>
          </div>
        </div>
      )}
      <div className="flex justify-left m-1 ">
        <div className="items-center justify-center w-2">
          {index < 10 ? index + 1 : ''}
        </div>
        <div className="w-full ml-2">
          <div style={{ width: `${8 + size}%` }} className={currentRowClass}>
            {label}
          </div>
        </div>
      </div>
    </div>
  )
}
