import Countdown from 'react-countdown'
import { StatBar } from '../stats/StatBar'
import { Histogram } from '../stats/Histogram'
import { GameStats } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
import { tomorrow } from '../../lib/words'
import { BaseModal } from './BaseModal'
import {
  GUESS_DISTRIBUTION_TEXT,
  NEW_WORD_TEXT,
  SHARE_TEXT,
} from '../../constants/strings'
import { ProgressLeaderboard } from '../stats/ProgressLeaderboard'
import { useEffect, useState } from 'react'
import { getStatsFirebaseWeekly } from '../../services/firebase'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { getWeekNumber } from '../../lib/date'

type Props = {
  isOpen: boolean
  handleClose: () => void
  solution: string
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
  setIsLeagueInfoModalOpen: (value: boolean) => void
}

export const StatsModal = ({
  isOpen,
  handleClose,
  solution,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
  numberOfGuessesMade,
  setIsLeagueInfoModalOpen,
}: Props) => {
  let [userScores, setUserScores] = useState<any[]>([])

  useEffect(() => {
    if (isOpen) {
      getUserScores()
    }
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  const getUserScores = async () => {
    await getStatsFirebaseWeekly().then((value: any) => {
      userScores = value
      if (userScores) setUserScores(value)
    })
  }

  const getDate = () => {
    const today = new Date()

    const startOfWeek = new Date(
      today.setDate(
        today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
      )
    )
    startOfWeek.setHours(0, 0, 0)

    const endOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay() + 7)
    )
    endOfWeek.setHours(23, 59, 0)

    return (
      startOfWeek.toLocaleDateString('es-us', {
        month: 'long',
        day: 'numeric',
      }) +
      ' al ' +
      endOfWeek.toLocaleDateString('es-us', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    )
  }

  return (
    <BaseModal title="Puntajes" isOpen={isOpen} handleClose={handleClose}>
      <StatBar gameStats={gameStats} />
      <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
        {GUESS_DISTRIBUTION_TEXT}
      </h4>
      <Histogram
        gameStats={gameStats}
        isGameWon={isGameWon}
        numberOfGuessesMade={numberOfGuessesMade}
      />
      {userScores.length > 0 && (
        <div>
          <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
            Top 10 Global
          </h4>
          <div className="items-center justify-center m-1 dark:text-white">
            <div className="text-xs">
              Semana número {getWeekNumber()} del {new Date().getFullYear()}
            </div>
          </div>
          <div className="columns-1 justify-left m-2 mt-1 text-sm dark:text-white">
            {userScores.map((userScore: any, i) => (
              <ProgressLeaderboard
                key={i}
                name={userScore.name}
                index={i}
                isCurrentDayStatRow={
                  userScore.isWinning || i === 0 ? true : false
                }
                //size={90 * (value / maxValue)}
                size={90}
                label={
                  (userScore.isLastWeekWinner || userScore.isWinner
                    ? '🏆 '
                    : '') +
                  userScore.name.split(' ')[0] +
                  ' - ' +
                  userScore.score +
                  ' Puntos'
                }
              />
            ))}
          </div>
          {/* <div className="items-center justify-center dark:text-white">
            <div className="columns-1 justify-left m-2 mt-1 text-sm dark:text-white">
              <div className="flex justify-left m-1 ">
                <div className="items-center justify-center w-2"></div>
                <div className="w-full ml-2">
                  <div
                    style={{ width: `${8 + 90}%` }}
                    className="text-xs font-medium text-green-100 text-center p-0.5 "
                    onClick={() => {
                      handleClose()
                      setIsLeagueInfoModalOpen(true)
                    }}
                  >
                    Reglas Multijugar
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
            Campeón Actual
          </h4>
          <div className="columns-1 justify-left m-2 mt-1 text-sm dark:text-white">
            <div className="flex justify-left m-1 ">
              <div className="items-center justify-center w-2"></div>
              <div className="w-full ml-2">
                <div
                  className=" text-xs font-medium text-green-100 text-center p-0.5 bg-stone-600"
                  style={{ width: `${8 + 90}%` }}
                >
                  {userScores[0].name} 🏆
                </div>
              </div>
            </div> 
          </div>*/}
        </div>
      )}
      {(isGameLost || isGameWon) && (
        <div className="mt-5 sm:mt-6 columns-2 dark:text-white">
          <div>
            <h5>{NEW_WORD_TEXT}</h5>
            <Countdown
              className="text-lg font-medium text-gray-900 dark:text-gray-100"
              date={tomorrow}
              daysInHours={true}
            />
          </div>
          <button
            type="button"
            className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
            onClick={() => {
              shareStatus(
                solution,
                guesses,
                isGameLost,
                isHardMode,
                isDarkMode,
                isHighContrastMode,
                handleShareToClipboard
              )
            }}
          >
            {SHARE_TEXT}
          </button>
        </div>
      )}
    </BaseModal>
  )
}
