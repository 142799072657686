import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const LeagueInfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal
      title="Clasificación Global"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
        Cada semana tendrás la oportunidad de competir con otros jugadores en la
        clasificación global.
      </p>
      <p className="mt-2 font-medium text-lg text-gray-500 dark:text-gray-300">
        Reglas
      </p>
      <ul className="mt-2 list-decimal list-inside text-xs text-gray-500 dark:text-gray-300">
        <li className="mb-1 mt-1">
          La clasificación global se reinicia cada semana
        </li>
        <li className="mb-1 mt-1">
          Intentos fallidos restan 1 punto de un máximo de 6
        </li>
        <li className="mb-1 mt-1">
          Solo cuentan tus mejores 5 resultados cada semana
        </li>
      </ul>
      <p className="mt-3 text-sm text-gray-500 dark:text-gray-300">
        El campeón de la semana pasada es marcado en la tabla de clasificación
        global con un trofeo 🏆
      </p>
      <p className="mt-4 font-medium text-lg text-gray-500 dark:text-gray-300">
        ¡Buena suerte!
      </p>
    </BaseModal>
  )
}
